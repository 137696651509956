import {WindowEnvironment} from '@cindedi/window-environment';
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react';
import type {LoaderFunction} from '@remix-run/server-runtime';
import entryStyles from '~/entry.styles.css?url';

export interface LoaderData {
  ENV: Application.PublicEnvironmentVariables;
}

export const loader: LoaderFunction = async function RootLoader({context: {env}}) {
  return json<LoaderData>({
    ENV: {
      APP_NAME: env('APP_NAME'),
      APP_URL: env('APP_URL'),
      ENCRYPTION_PUBLIC_IV: env('ENCRYPTION_PUBLIC_IV'),
      ENCRYPTION_PUBLIC_KEY: env('ENCRYPTION_PUBLIC_KEY'),
    },
  });
};

export default function RootView() {
  const {ENV} = useLoaderData<LoaderData>();

  return (
    <html lang="en" data-theme="light">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://rsms.me" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <link rel="stylesheet" href={entryStyles} />
        <link rel="icon" type="image/png" href="/images/favicon.png" />
        <Meta />
        <Links />
        <WindowEnvironment ENV={ENV} />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
